// Copyright (C) 2022 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from 'src/app/shared/alert.service';
import { FileInfo, FileUrl } from 'src/app/storage/file-info.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EntityStorageService {
  constructor(
    protected alertService: AlertService,
    protected httpClient: HttpClient,
  ) {}

  listFiles$(entity: string, id: number): Observable<FileInfo[]> {
    const url = this.getWebApiEndpoint(entity, id, 'files');
    return this.httpClient.get<FileInfo[]>(url).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error, entity, id);

        this.alertService.showError(`Failed to list files for ${entity}`);

        return throwError(() => error);
      }),
    );
  }

  uploadFile$(entity: string, id: number, file: File, formData: FormData = new FormData()): Observable<FileInfo[]> {
    formData.append('file', file, file.name);

    const url = this.getWebApiEndpoint(entity, id, 'files');
    return this.httpClient.post<FileInfo[]>(url, formData).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error, entity, id);

        this.alertService.showError(`Failed to upload file for ${entity}`);

        return throwError(() => error);
      }),
    );
  }

  downloadFile$(entity: string, id: number, fileName: string): Observable<FileUrl> {
    const url = this.getWebApiEndpoint(entity, id, 'files');
    const params = { fileName };
    return this.httpClient.get<FileUrl>(url, { params }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error, entity, id);

        this.alertService.showError(`Failed to download file for ${entity}`);

        return throwError(() => error);
      }),
    );
  }

  deleteFile$(entity: string, id: number, fileName: string): Observable<null> {
    const url = this.getWebApiEndpoint(entity, id, 'files');
    // For a DELETE, any data to be sent with the request must be in the body not the query params.
    const body = { fileName };
    return this.httpClient.delete<null>(url, { body }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error, entity, id);

        this.alertService.showError(`Failed to delete file for ${entity}`);

        return throwError(() => error);
      }),
    );
  }

  protected getWebApiEndpoint(entity: string, resourceId?: number, action?: string) {
    const _resourceId = resourceId ? `/${resourceId}` : '';
    const _action = action ? `/${action}` : '';

    return `${environment.server.apiURL}/${entity}${_resourceId}${_action}/`;
  }
}
