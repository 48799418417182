// @ts-strict-ignore
// Copyright (C) 2022 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, first, tap } from 'rxjs/operators';
import { EntityStorageService } from 'src/app/core/entity/entity-storage.service';

import { FileInfo, FileUrl } from '../file-info.model';

@Component({
  selector: 'app-storage-viewer',
  templateUrl: './storage-viewer.component.html',
  styleUrls: ['./storage-viewer.component.scss'],
})
export class StorageViewerComponent implements OnInit {
  /**
   * The entity that the files are for e.g. files uploaded for a QuestionResponse.
   */
  @Input()
  entityName: string;

  @Input()
  entityId: number;

  // TODO API: Not utilized yet at the moment. To be implemented.
  @Input()
  showFolders = true;

  @Input()
  canUpload = true;

  @Input()
  canDelete = true;

  @Output()
  filesLoaded = new EventEmitter<FileInfo[]>();

  files: FileInfo[] = [];

  modalDeleteVisible = false;

  selectedForDelete: FileInfo;

  constructor(private storageService: EntityStorageService) {}

  ngOnInit(): void {
    const files$ = this.storageService.listFiles$(this.entityName, this.entityId);
    const stream$ = this.onFilesUpdated(files$);
    stream$.subscribe();
  }

  // TODO API: To be implemented.
  // viewFolder(path: string) {
  // }

  // TODO API: To be implemented.
  // viewParentFolder(path: string) {
  // }

  /**
   * View/Download a file's contents.
   */
  viewFile(file: FileInfo) {
    this.storageService
      .downloadFile$(this.entityName, this.entityId, file.name)
      .pipe(first())
      .subscribe({
        next: (fileUrl: FileUrl) => window.open(fileUrl.url, '_blank'),
      });
  }

  deleteFile(file: FileInfo) {
    this.selectedForDelete = file;
    this.modalDeleteVisible = true;
  }

  doDeleteFile(confirmed: boolean) {
    const file = this.selectedForDelete;
    this.selectedForDelete = null;
    if (confirmed) {
      const files$ = this.storageService.deleteFile$(this.entityName, this.entityId, file.name);
      const stream$ = this.onFilesUpdated(files$);
      stream$.subscribe();
    }
  }

  onFileUpload(event: { files: File[]; progress: (amount: number) => void; finish: () => void }) {
    const files$ = this.storageService.uploadFile$(this.entityName, this.entityId, event.files[0]);
    const stream$ = this.onFilesUpdated(files$);
    stream$.pipe(finalize(() => event.finish())).subscribe();
  }

  /**
   * Common behaviour when list of files has been updated/retrieved from the server.
   */
  private onFilesUpdated(files$: Observable<FileInfo[]>): Observable<FileInfo[]> {
    return files$.pipe(
      first(),
      tap((files: FileInfo[]) => {
        this.filesLoaded.emit(files);
        this.files = files;
      }),
    );
  }
}
