<!--
 Copyright (C) 2022 Fair Supply Analytics Pty Ltd - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited.
 Proprietary and confidential.
-->

<div class="row">
  <div *ngIf="canUpload" class="col-lg-6 mb-5 mb-lg-0">
    <app-file-upload (event)="onFileUpload($event)"></app-file-upload>
  </div>

  <div [class]="canUpload ? 'col-lg-6' : 'col'">
    <!--<div *ngIf="showFolders">
      // TODO API To be implemented.
    </div> -->

    <!--Not using *ngIf="files$ | async as files" to avoid "UI flash" when after uploading a new file or deleting a file.
      "flash" occurs due to new Observable instance files$ being created and it has not been `| async-ed` yet. To prevent
      "flash", we control when we set the new files list by subscribing to the new Observable.
    -->
    <div *ngIf="files?.length > 0">
      <ul class="list-group">
        <li *ngFor="let file of files" class="list-group-item list-group-item-action d-flex justify-content-between">
          <button type="button" class="btn btn-link btn-sm align-self-center w-100" (click)="viewFile(file)">
            {{ file.name }}
          </button>
          <button type="button" class="btn btn-danger btn-sm align-self-center" *ngIf="canDelete" (click)="deleteFile(file)">Delete</button>
        </li>
      </ul>
    </div>

    <modal [title]="'Confirm delete'" [(visible)]="modalDeleteVisible" (confirmed)="doDeleteFile($event)">
      <div class="body-contents">This will delete the selected file {{ (selectedForDelete && selectedForDelete.name) || '' }}. Proceed?</div>
    </modal>
  </div>
</div>
